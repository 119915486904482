import { useRef } from "react";
import Join from "./Join";


function TenPoint() {


    return <div className="ten-pt">
        <h1>Ten Point Program</h1>
        <p>
We are committed to building a movement that is led by tenants, for tenants. We reject the notion that housing should be treated as a commodity, and we oppose the systems of exploitation and inequality that have made stable housing inaccessible for so many.
        </p>
        <p>
Our work is guided by principles of revolutionary organizing, direct action and community solidarity. We believe in the power of tenant unions, the importance of education and empowerment, and the necessity of challenging systemic injustices head-on.  
        </p>
        <p>
The following points outline the core values and beliefs that shape our work and guide our actions. They represent our vision for a world where everyone has access to safe, affordable, and dignified housing.
        </p>
        <ol>
            <li>
                <h3>Housing as a Human Right</h3>
                <p>
                We believe that safe, stable, and affordable housing is a fundamental human right, not a commodity. Everyone deserves a home, regardless of income, background, or status.
                </p>
            </li>
            <li>
                <h3>Direct Tenant Organizing</h3>
                <p>
                We empower tenants to collectively organize within their buildings and communities to advocate for their rights, share resources, and build solidarity against exploitative practices by landlords and property management companies.
                </p>
            </li>
            <li>
                <h3>Direct Action</h3>
                <p>
                We engage in direct actions, including protests, rent strikes, and community defense, to confront and disrupt systems that exploit and displace tenants. We prioritize tactics that directly challenge power and create immediate change.
                </p>
            </li>
            <li>
                <h3>Horizontal Structure</h3>
                <p>
                We operate with a horizontal structure, ensuring that all voices within the organization have equal weight. Decisions are made collectively, and leadership is decentralized to prevent hierarchies and maintain accountability to the group.                </p>
            </li>
            <li>
                <h3>Tenant Unions</h3>
                <p>
                We support and advocate for the formation and strengthening of tenant unions that can collectively bargain with landlords, fight for better living conditions, and defend against unjust evictions.                </p>
            </li>
            <li>
                <h3>Anti-Displacement</h3>
                <p>
                We resist all forms of gentrification and displacement. We fight to keep long-term residents in their homes and neighborhoods, and we oppose policies and practices that prioritize profit over people.                </p>
            </li>
            <li>
                <h3>Solidarity with Houseless Individuals</h3>
                <p>
                We stand in solidarity with houseless individuals, recognizing that the struggle for housing rights is deeply connected to the broader fight against poverty and systemic inequality. We support initiatives that provide immediate assistance while working toward long-term solutions.
                </p>
            </li>
            <li>
                <h3>Community Control of Housing</h3>
                <p>
                We advocate for community ownership and control of housing. This includes supporting cooperatives, land trusts, and other models that remove housing from the speculative market and place it under the democratic control of residents.                </p>
            </li>
            <li>
                <h3>Anti-Racism and Inclusivity</h3>
                <p>
                We are committed to an anti-racist approach in all our work. We acknowledge that housing injustice disproportionately affects marginalized communities which have historically been indigenous, black, and LGBTQ+, and we strive to build an inclusive movement that centers the voices of those most impacted.                </p>
            </li>
            <li>
                <h3>Education and Empowerment</h3>
                <p>
                We prioritize educating tenants about their rights and the tools available to them. We offer resources and training to empower tenants to advocate for themselves and their communities.
                </p>
            </li>
        </ol>
        <Join></Join>

    </div>
}

export default TenPoint;